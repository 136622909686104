<template>
  <div class="fill-height pb-2" style="max-width: 1280px;">
    <h1 class="text-h4">{{ $lang.header.moduleDetails }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row wrap no-gutters class="pb-2">
      <v-col
        v-if="!userCanEdit && !initialLoading"
        cols="12"
      >
        <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
      </v-col>
      <v-col
        cols="12"
      >
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="0"
            @click="validationShow = false; validationResult = null"
          >
            {{ $lang.actions.import }}
          </v-tab>
          <v-tab
            v-if="validationShow"
            :key="1"
          >
            {{ $lang.labels.validation }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pb-6">
          <v-tab-item
            :key="0"
          >
            <v-card flat class="pa-2" style="border-radius: 0">
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-row wrap no-gutters justify="space-between" class="py-1">
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      ref="fileTextField"
                      v-model="file"
                      prepend-icon="mdi-paperclip"
                      outlined
                      solo
                      dense
                      hide-details
                      :label="$lang.actions.clickToImport"
                      required
                      data-cy="file-import"
                      class="min-w"
                      :rules="[v => !!v || $lang.labels.required]"
                      @click="startInput('fileInput')"
                      @click:prepend="startInput('fileInput')"
                    />
                    <input
                      ref="fileInput"
                      style="display: none;"
                      type="file"
                      accept=".json,application/json"
                      @change="onFileChange($event)"
                    >
                  </v-col>
                </v-row>
                <v-row no-gutters wrap justify="space-between" class="pt-1">
                  <v-col
                    cols="6"
                    class="text-left"
                  >
                    <v-btn
                      class="button-default-width"
                      color="secondary"
                      :to="{ name: 'local', params: { lang: $lang.current_lang } }"
                    >
                      <v-icon
                        right
                        dark
                        class="ma-0"
                      >
                        mdi-arrow-left
                      </v-icon>
                      <span class="ml-1">{{ $lang.actions.back }}</span>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-right"
                  >
                    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                    <v-btn
                      :disabled="!valid || lock || !userCanEdit"
                      color="accent"
                      class="button-default-width ml-2 color-accent-text"
                      @click="validateModuleFunction()"
                    >
                      {{ $lang.actions.validate }}
                    </v-btn>
                    <v-btn
                      :disabled="!valid || lock || !userCanEdit"
                      color="success"
                      class="button-default-width ml-2"
                      @click="importModuleFunction()"
                    >
                      {{ $lang.actions.import }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item
            :key="1"
            class="fill-height"
          >
            <v-card flat class="pa-2" style="border-radius: 0">
              <v-row wrap no-gutters class="pb-4">
                <v-col cols="12" class="pb-4">
                  <v-card flat class="pa-2">
                    <v-card-title>
                      <h4 class="text-h5">{{ $lang.labels.report }}</h4>
                    </v-card-title>
                    <v-row wrap no-gutters class="pb-2">
                      <v-col cols="12" md="6">
                        <v-card-subtitle>
                          <h4 class="text-h6">{{ $lang.labels.existingObjects }}: <span :class="existingObjects > 0 ? 'color-error' : 'color-primary'">{{ existingObjects }}</span></h4>
                        </v-card-subtitle>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card-subtitle>
                          <h4 class="text-h6">{{ $lang.labels.newObjects }}: <span :class="newObjects === 0 ? 'color-error' : 'color-primary'">{{ newObjects }}</span></h4>
                        </v-card-subtitle>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card-subtitle>
                          <h4 class="text-h6">{{ $lang.labels.missingResources }}: <span :class="missingResources > 0 ? 'color-error' : 'color-primary'">{{ missingResources }}</span></h4>
                        </v-card-subtitle>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card-subtitle>
                          <h4 class="text-h6">{{ $lang.labels.conflicts }}: <span :class="conflicts > 0 ? 'color-error' : 'color-primary'">{{ conflicts }}</span></h4>
                        </v-card-subtitle>
                      </v-col>
                      <v-col cols="12" class="mt-2">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-card-title>
                      <h4 class="text-h5 pb-2">{{ $lang.labels.filters }}</h4>
                    </v-card-title>
                    <v-row wrap no-gutters class="pb-2">
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="filterExistsValue"
                          :items="filterExistsFormatted"
                          :label="$lang.labels.exists"
                          class="pr-md-1 mb-2"
                          dense
                          outlined
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="filterMissingValue"
                          :items="filterMissingFormatted"
                          :label="$lang.labels.missing"
                          class="pl-md-1 mb-2"
                          dense
                          outlined
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="filterConflictsValue"
                          :items="filterConflictsFormatted"
                          :label="$lang.labels.conflicts"
                          class="pr-md-1 mb-2"
                          dense
                          outlined
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="12" class="mt-2">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-expansion-panels color="surface">
                    <template v-for="(item, i) in filteredValidations">
                      <v-expansion-panel
                        :key="i"
                      >
                        <v-expansion-panel-header>
                          {{ $lang.status[item.type] }}: <span :class="(item.missedRequiredResources && item.missedRequiredResources.length > 0) || !item.hasPermission || item.isExist ? 'color-error' : 'color-primary'">&nbsp;{{ item.name }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row wrap no-gutters class="pb-2">
                            <v-col cols="6">
                              <h4>&nbsp;{{ $lang.labels.permission }}: <span :class="item.hasPermission ? 'color-primary' : 'color-error'">&nbsp;{{ item.hasPermission ? $lang.labels.yes : $lang.labels.no }}</span></h4>
                            </v-col>
                            <v-col cols="6">
                              <h4>&nbsp;{{ $lang.labels.exist }}: <span :class="item.isExist ? 'color-error' : 'color-primary'">&nbsp;{{ item.isExist ? $lang.labels.yes : $lang.labels.no }}</span></h4>
                            </v-col>
                          </v-row>
                          <v-row v-if="item.missedRequiredResources && item.missedRequiredResources.length > 0" no-gutters class="pb-2">
                            <v-divider></v-divider>
                          </v-row>
                          <div v-if="item.missedRequiredResources && item.missedRequiredResources.length > 0" class="pb-2" style="width: 100%">
                            <h3 class="color-error pb-2">&nbsp;{{ $lang.labels.missingParams }}</h3>
                            <template v-for="(missing, index) in item.missedRequiredResources">
                              <v-row :key="index" no-gutters class="pb-2">
                                <v-col cols="12">
                                  <h4>&nbsp;{{ $lang.status[missing.type] }}: <span class="color-error">&nbsp;{{ missing.name }}</span></h4>
                                </v-col>
                              </v-row>
                            </template>
                          </div>
                          <v-row v-if="item.conflicts && item.conflicts.length > 0" no-gutters class="pb-2">
                            <v-divider></v-divider>
                          </v-row>
                          <div v-if="item.conflicts && item.conflicts.length > 0" class="pb-2" style="width: 100%">
                            <h3 class="color-error pb-2">&nbsp;{{ $lang.labels.conflicts }}</h3>
                            <template v-for="(conflict, index) in item.conflicts.flat(Infinity)">
                              <v-row :key="index" no-gutters class="pb-2">
                                <v-col cols="12">
                                  <h4>&nbsp;{{ index }}: <span class="color-error">&nbsp;{{ conflict }}</span></h4>
                                </v-col>
                              </v-row>
                            </template>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </template>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  getModuleByIdUsingGET as getModule,
  getRolesUsingGET as getRoles,
  getHistoriesUsingGET as getHistories,
  importModuleUsingPOST as importModule,
  validateUsingPOST as validateModule
} from '@/utils/api'
import auth from '@/auth'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      err: '',
      success: '',
      tab: 0,
      validationShow: false,
      valid: false,
      isEdit: false,
      initialLoading: true,
      loading: true,
      lock: false,
      file: null,
      fileData: null,
      resourcesTypes: ['GLOBAL_TRANSLATION', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_REST'],
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      validationResult: null,
      filterExists: ['all', 'onlyExists', 'onlyNotExists'],
      filterMissing: ['all', 'onlyMissing', 'onlyNotMissing'],
      filterConflicts: ['all', 'onlyConflicts', 'onlyNotConflicts'],
      filterExistsValue: 'all',
      filterMissingValue: 'all',
      filterConflictsValue: 'all'
    }
  },
  computed: {
    filterExistsFormatted() {
      return this.filterExists.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    filterMissingFormatted() {
      return this.filterMissing.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    filterConflictsFormatted() {
      return this.filterConflicts.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    filteredValidations() {
      if (!this.validationResult) return []

      let result = this.validationResult

      if (this.filterExistsValue === 'onlyExists') {
        result = result.filter((x) => x.isExist)
      } else if (this.filterExistsValue === 'onlyNotExists') {
        result = result.filter((x) => !x.isExist)
      }

      if (this.filterMissingValue === 'onlyMissing') {
        result = result.filter((x) => x.missedRequiredResources && x.missedRequiredResources.length > 0)
      } else if (this.filterMissingValue === 'onlyNotMissing') {
        result = result.filter((x) => !x.missedRequiredResources || x.missedRequiredResources.length === 0)
      }

      if (this.filterConflictsValue === 'onlyConflicts') {
        result = result.filter((x) => x.conflicts && x.conflicts.length > 0)
      } else if (this.filterConflictsValue === 'onlyNotConflicts') {
        result = result.filter((x) => !x.conflicts || (x.conflicts && x.conflicts.length === 0))
      }

      return result
    },
    existingObjects() {
      return this.validationResult ? this.validationResult.filter((x) => x.isExist).length : 0
    },
    newObjects() {
      return this.validationResult ? this.validationResult.filter((x) => !x.isExist).length : 0
    },
    missingResources() {
      return this.validationResult ? this.validationResult.filter((x) => x.missedRequiredResources && x.missedRequiredResources.length > 0).length : 0
    },
    conflicts() {
      return this.validationResult ? this.validationResult.filter((x) => x.conflicts && x.conflicts.length > 0).length : 0
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDate(this.module.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDate(this.module.modifiedOn)}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    }
  },
  watch: {
    file: {
      handler (val) {
        // this.onFileChange(val)
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'moduleEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getModule({ id: this.$route.params.id })
            .then((res) => {
              this.module = res.data.data

              this.editRolesIds = this.module.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.module.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.module.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              this.loading = false
              this.initialLoading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })
              this.loading = false
              this.initialLoading = false
            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.loading = false
          this.initialLoading = false
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    startInput(name) {
      this.$refs[name].click()
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      this.fileData = files[0]
      this.file = `${this.$lang.labels.fileLoaded}: ${this.fileData.name}`
    },
    async validateModuleFunction() {

      if (!this.fileData) return

      this.loading = true
      this.lock = true

      const data = new FormData()

      data.append('file', this.fileData)

      fetch('/api/module/validate', {
        method: 'post',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'x-auth-token': auth.getToken()
        },
        body: data
      }).then((response) => {
        return response.json()
      }).then((res) => {
        if (res && res.status !== 'SUCCESS') {

          this.addSnackbar({
            message: this.$lang.errors.moduleValidate,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }

        this.loading = false
        this.lock = false

        this.addSnackbar({
          message: this.$lang.success.moduleValidate,
          timeout: 5000,
          color: 'success'
        })
        this.validationResult = res.data
        this.validationShow = true
        this.tab = 1
      }).catch((err) => {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })

        this.loading = false
        this.lock = false
      })
    },
    async importModuleFunction() {
      if (!this.fileData) return

      this.loading = true
      this.lock = true

      const data = new FormData()

      data.append('file', this.fileData)

      fetch('/api/module/import', {
        method: 'post',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'x-auth-token': auth.getToken()
        },
        body: data
      }).then((response) => {
        return response.json()
      }).then((res) => {
        if (res && res.status !== 'SUCCESS') {

          this.addSnackbar({
            message: `${this.$lang.errors.moduleImport}: ${res.statusText}`,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          if (res.data) {
            this.validationResult = res.data
            this.validationShow = true
            this.tab = 1
          }

          return
        }

        this.loading = false
        this.lock = false

        this.addSnackbar({
          message: this.$lang.success.moduleImport,
          timeout: 5000,
          color: 'success'
        })
        if (res.data) {
          this.validationResult = res.data
          this.validationShow = true
          this.tab = 1
        }
      }).catch((err) => {

        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      })
    },
    setResources(type, data) {
      const tempByType = this.module.resources.filter((x) => x.type !== type)

      const formattedData = data.map((x) => {
        return { type, id: x.id }
      })

      this.module.resources = [...tempByType, ...formattedData]
    },
    fetchHistory(options) {
      if (!this.module.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.module.id
      obj.resourceType = 'MODULE'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    restoreModule(data) {
      this.module = data

      if (this.module && this.module.roles) {
        this.editRolesIds = this.module.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.module.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.module.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    }
  }
}
</script>
